<template>
  <el-dialog
    :title="$t('components.story_edit_send_to_journalist_dialog.title')"
    :visible.sync="isVisible"
    width="50%"
    class="StoryEditSendToJournalistDialog is-fullscreen-mobile"
    data-testid="StoryEditSendToJournalistDialog"
  >
    <div>
      <div class="m-b-l">
        <label class="label">{{ $t('components.story_edit_send_to_journalist_dialog.send_as_label') }}</label>
        <el-radio-group v-model="sendAs" data-testid="sendAsRadioGroup">
          <el-radio
            v-for="option in sendAsOptions"
            :key="option"
            :label="option"
            :data-testid="`RadioSendAs--${option}`"
          >
            {{ $t(`components.story_edit_send_to_journalist_dialog.send_as.${option}`) }}
          </el-radio>
        </el-radio-group>
      </div>
      <div v-if="storyHasFiles" class="m-b-m">
        <label class="label">{{ $t('components.story_edit_send_to_journalist_dialog.attach_attachments_label') }}</label>
        <el-radio-group v-model="attachAssets" data-testid="attachAssetsRadioGroup">
          <el-radio :label="true">
            {{ $t('general.yes') }}
          </el-radio>
          <el-radio :label="false">
            {{ $t('general.no') }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div slot="footer">
      <v-button
        :loading="isLoading"
        class="is-primary"
        data-testid="sendAsEmail"
        @click="sendAsEmail"
      >
        {{ $t('components.story_edit_send_to_journalist_dialog.send_as_email') }}
      </v-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { PRPreviewDialogGetters, PRPreviewDialogMethods } from '@/store/modules/newsroom/helpers'
import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Renders a modal that asks the user how the send
 * the PR - inline, attached as pdf etc.
 * @module StoryEditSendToJournalistDialog
 */
export default {
  name: 'StoryEditSendToJournalistDialog',

  data () {
    return {
      isLoading: false,
      sendAsOptions: [
        'inline', 'pdf', 'both'
      ],
      // Form state
      /** @type {'both'|'pdf'|'inline'} */
      sendAs: 'both',
      attachAssets: true
    }
  },

  computed: {
    ...PRPreviewDialogGetters,

    ...mapGetters('newsroom', ['storyHasFiles', 'sendToJournalistDialogVisibility', 'storyId']),

    isVisible: generateComputedSetterWithMethod('sendToJournalistDialogVisibility', 'SET_SEND_TO_JOURNALIST_DIALOG_VISIBILITY')
  },

  watch: {
    isVisible (state) {
      if (state) {
        this.attachAssets = this.storyHasFiles
      }
    }
  },

  methods: {
    ...PRPreviewDialogMethods,

    ...mapMutations('newsroom', ['SET_SEND_TO_JOURNALIST_DIALOG_VISIBILITY']),

    async sendAsEmail () {
      this.isVisible = false
      this.$router.push({
        name: 'emails.new',
        query: {
          entity_id: this.storyId,
          type: 'story',
          mode: this.sendAs,
          add_attachments: this.attachAssets,
          link_to_pdf: this.sendAs !== 'inline' ? this.prPreviewPDF : null
        }
      })
    }
  }
}
</script>
