import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export const PRPreviewDialogGetters = {
  ...mapGetters('newsroom', [
    'PRPreviewDialogVisibility',
    'prPreviewPDF',
    'prPreviewImage'
  ]),

  isPRPreviewDialogVisible: generateComputedSetterWithMethod('PRPreviewDialogVisibility', 'SET_PR_PREVIEW_DIALOG_VISIBILITY')
}

export const PRPreviewDialogMethods = {
  ...mapMutations('newsroom', [
    'SET_PR_PREVIEW_DIALOG_VISIBILITY'
  ]),
  ...mapActions('newsroom', [
    'setPrPreviewData'
  ])
}
