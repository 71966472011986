<template>
  <page-section class="StoryList" hide-title>
    <template #above-title>
      <stories-filters :query.sync="searchQuery" class="m-b-m" />
    </template>
    <heading size="3" class="is-fullwidth" color="black">
      {{ $t('pages.story_list.draft_stories') }}
    </heading>
    <load-more
      :placeholder-items="4"
      :endpoint="draftStoriesUrl"
      width="is-4-tablet is-3-desktop column is-flex"
      placeholder="StoryPlaceholder"
      class="is-variable-grid is-1"
      data-testid="DraftStoryList"
    >
      <template slot="firstItem">
        <StoryAdd />
      </template>

      <template slot="item" slot-scope="props">
        <Story
          :story="props.item"
          class="has-background-grey-lighter"
          @delete="props.removeItem"
        />
      </template>
    </load-more>

    <heading size="3" color="black">
      {{ $t('pages.story_list.published_stories') }}
    </heading>

    <load-more
      :placeholder-items="4"
      :endpoint="publishedStoriesUrl"
      width="is-4-tablet is-3-desktop column is-flex"
      placeholder="StoryPlaceholder"
      class="is-variable-grid is-1"
      data-testid="PublishedStoryList"
    >
      <template slot="item" slot-scope="props">
        <story
          :story="props.item"
          @delete="props.removeItem"
        />
      </template>
    </load-more>
    <story-edit-send-to-journalist-dialog />
    <story-edit-pr-preview-dialog />
  </page-section>
</template>

<script>
import Story from '@/components/stories/Story'
import StoryAdd from '@/components/stories/StoryAdd'
import StoryEditPrPreviewDialog from '@/components/stories/StoryEdit/StoryEditPrPreviewDialog'
import StoryEditSendToJournalistDialog from '@/components/stories/StoryEdit/StoryEditSendToJournalistDialog'

import { mapGetters } from 'vuex'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'
import StoriesFilters from '@/components/stories/StoriesFilters'

export default {
  components: {
    StoriesFilters,
    StoryEditPrPreviewDialog,
    StoryEditSendToJournalistDialog,
    Story,
    StoryAdd
  },

  data () {
    return {
      searchQuery: ''
    }
  },

  computed: {
    ...mapGetters(['filtersQueryFor']),

    draftStoriesUrl () {
      return this.getEndpointWithParams('/stories',
        {
          brands: this.activeBrandId,
          published: 'false',
          sort: 'created',
          search: this.searchQuery
        })
    },

    publishedStoriesUrl () {
      return this.getEndpointWithParams('/stories',
        {
          brands: this.activeBrandId,
          published: 'true',
          sort: 'published',
          search: this.searchQuery
        })
    }
  },

  methods: {
    getEndpointWithParams (endpoint, extraParams) {
      return buildUrlWithParams(endpoint, {
        ...this.filtersQueryFor('story', false),
        ...extraParams
      })
    }
  }
}
</script>
