<template>
  <dashboard-filters-v2 class="CreateFilters">
    <template #primary>
      <slot name="primary" />
    </template>
    <template #secondary>
      <languages-provider v-if="showLanguage" #default="{ languages }">
        <dashboard-filters-v2-item>
          <dropdown-list
            :elements="languages"
            :selected-elements.sync="selectedNewsroomLanguages"
            :filter-placeholder="$t('components.create_filters.filter_by_name')"
            has-checkboxes
            filter-by="name"
            menu-classes="is-medium has-max-height"
            is-active-prop="code"
            value-key="code"
            key-by="code"
            label-key="name"
            class="Dashboard-filters__language-picker"
          >
            <template #trigger>
              <dashboard-filters-icon
                :label="$t('general.language')"
                class="DashboardFiltersV2-DropdownTrigger"
                icon="language"
              />
            </template>
          </dropdown-list>
        </dashboard-filters-v2-item>
      </languages-provider>
      <dashboard-filters-v2-item v-if="showCountry">
        <dropdown-list
          :elements="attachedCountriesToNewsrooms"
          :selected-elements.sync="selectedNewsroomCountries"
          :filter-placeholder="$t('components.create_filters.filter_by_name')"
          has-checkboxes
          filter-by="name"
          menu-classes="is-medium has-max-height"
          is-active-prop="iso_alpha2"
          value-key="iso_alpha2"
          key-by="iso_alpha2"
          label-key="name"
        >
          <template #trigger>
            <dashboard-filters-icon
              :label="$t('general.country')"
              class="DashboardFiltersV2-DropdownTrigger"
              icon="globe"
            />
          </template>
        </dropdown-list>
      </dashboard-filters-v2-item>
      <slot name="secondary" />
    </template>
    <template #tertiary>
      <slot name="tertiary" />
    </template>
  </dashboard-filters-v2>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LanguagesProvider from '@hypefactors/shared/js/components/core/LanguagesProvider'

/**
 * @module CreateFilters
 */
export default {
  name: 'CreateFilters',
  components: { LanguagesProvider },
  props: {
    showLanguage: {
      type: Boolean,
      default: true
    },
    showCountry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getFilter']),
    ...mapGetters('newsroom', ['attachedCountriesToNewsrooms']),
    selectedNewsroomLanguages: {
      get () {
        return this.getFilter('languages', [])
      },
      set (value) {
        this.setFilter({
          name: 'languages',
          value: value
        })
      }
    },
    selectedNewsroomCountries: {
      get () {
        return this.getFilter('countries', [])
      },
      set (value) {
        this.setFilter({
          name: 'countries',
          value: value
        })
      }
    }
  },
  methods: {
    ...mapActions(['setFilter'])
  }
}
</script>
