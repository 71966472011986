<template>
  <create-filters :show-country="false">
    <template #primary>
      <form-field
        left-icon="search"
        icon-class="hf-lg"
      >
        <input
          :value="queryLocal"
          :placeholder="$t('components.stories_filters.search_input_placeholder')"
          type="text"
          class="input is-transparent is-size-4 has-text-weight-semibold"
          data-testid="inputFilterByStoryHeadline"
          @input="handleQueryChange"
        >
      </form-field>
    </template>
  </create-filters>
</template>

<script>
import CreateFilters from '@/components/stories/CreateFilters'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module StoriesFilters
 */
export default {
  name: 'StoriesFilters',
  components: { CreateFilters },
  mixins: [debounceMixin(['handleQueryChange'])],
  props: {
    query: {
      type: String,
      default: ''
    }
  },
  computed: {
    queryLocal: generateComputedSetterWithEmit('query', 'update:query')
  },
  methods: {
    handleQueryChange (e) {
      this.queryLocal = e.target.value
    }
  }
}
</script>
