<template>
  <el-tooltip
    :disabled="!isDisabled"
    :content="$t('components.story_edit_send_to_journalist_button.tooltip_warning')"
  >
    <v-button
      :disabled="isDisabled"
      class="StorySendToJournalistsButton is-unstyled"
      data-testid="SendToJournalistsButton"
      @click="openJournalistDialog"
    >
      <slot>
        <dashboard-filters-icon
          icon="envelope"
          :is-dropdown="false"
          :label="$t('forms.send')"
        />
      </slot>
    </v-button>
  </el-tooltip>
</template>

<script>
import { mapActions } from 'vuex'

/**
 * @module StorySendToJournalistsButton
 */
export default {
  name: 'StorySendToJournalistsButton',

  props: {
    hasUnsavedChanges: {
      type: Boolean,
      required: true
    },
    isNewStory: {
      type: Boolean,
      required: true
    },
    storyId: {
      type: String,
      default: ''
    },
    storyUpdatedAt: {
      type: String,
      default: ''
    },
    hasFiles: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isDisabled () {
      return this.isNewStory || this.hasUnsavedChanges
    }
  },

  methods: {
    ...mapActions('newsroom', ['openSendToJournalistDialog']),
    openJournalistDialog () {
      if (this.isDisabled) return

      this.openSendToJournalistDialog({
        storyId: this.storyId,
        storyUpdatedAt: this.storyUpdatedAt,
        storyHasFiles: this.hasFiles
      })
    }
  }
}
</script>
