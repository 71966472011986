<template>
  <el-dialog
    :visible.sync="isPRPreviewDialogVisible"
    width="80%"
    class="StoryEditPrPreviewDialog is-fullscreen-mobile"
    data-testid="StoryEditPrPreviewDialog"
  >
    <div>
      <div class="buttons is-aligned-end">
        <a
          :href="prPreviewPDF"
          target="_blank"
          class="button is-outlined is-dark"
          data-testid="DownloadSamplePDF"
        >
          {{ $t('components.story_edit_pr_preview_dialog.download_preview_pdf') }}
        </a>
      </div>
      <div class="has-text-centered">
        <img
          :src="prPreviewImage"
          :alt="$t('components.story_edit_pr_preview_dialog.generated_pr_preview')"
          data-testid="StoryEditPrPreview__ImagePreview"
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  PRPreviewDialogGetters,
  PRPreviewDialogMethods
} from '@/store/modules/newsroom/helpers'

/**
 * Generates the PR Preview dialog
 * @module StoryEditPrPreviewDialog
 */
export default {
  name: 'StoryEditPrPreviewDialog',
  computed: {
    ...PRPreviewDialogGetters
  },
  methods: {
    ...PRPreviewDialogMethods
  }
}
</script>
