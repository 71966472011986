<template>
  <div
    :class="{ 'Story--draft': !story.is_published }"
    class="Story card"
  >
    <div
      class="Story__LinkWrap"
      data-testid="storyEditLink"
    >
      <div class="Story__header card-header is-position-relative">
        <div class="is-flex is-aligned-middle">
          <router-link
            :to="editLink"
            :title="$t('forms.edit')"
            class="Story__date m-r-a is-flex is-aligned-middle"
          >
            <country-flag v-if="newsroom && newsroom.country" :country="newsroom.country.iso_alpha2" class="m-r-s" />
            <template v-if="story.is_published">
              {{ datetime(story.first_published_at, 'Do MMM YYYY') }}
            </template>
            <template v-else>
              {{ datetime(story.created_at, 'Do MMM YYYY') }}
            </template>
          </router-link>
          <router-link
            v-if="story.is_published"
            :to="{ name: 'story.facts', params: { storyId: story.id } }"
            class="m-r-s"
            data-testid="StoryAnalyticsTrigger"
          >
            <v-icon type="reports" size="lg" />
          </router-link>
          <router-link :to="editLink">
            <v-icon type="pencil" size="lg" />
          </router-link>
        </div>
      </div>
      <div class="Story__image card-image">
        <router-link :to="editLink">
          <figure class="image is-16by9">
            <div
              :style="{ backgroundImage: `url(${coverImage})` }"
              class="floated is-flex"
            >
              <div
                v-if="!coverImage"
                class="Story__image-upload"
              >
                <v-icon
                  class="icon-faded"
                  type="landscape"
                />
                <div class="m-b-1">
                  {{ $t('forms.upload_an_image') }}
                </div>
              </div>
            </div>
          </figure>
        </router-link>
      </div>
      <router-link
        :to="editLink"
        class="Story__body card-content"
      >
        <div class="Story__title m-b-m has-text-weight-bold">
          {{ story.headline || $t('components.story.untitled_story') }}
        </div>

        <div class="Story__content">
          {{ truncate(story.excerpt, 100) }}
        </div>
      </router-link>
    </div>
    <div class="card-footer">
      <div
        v-if="story.is_published"
        class="card-footer-item"
      >
        <a :href="viewUrl" target="hn_view_story">
          <v-icon type="eye" size="lg" />
        </a>
      </div>
      <div class="card-footer-item">
        <el-tooltip
          :content="$t('components.story.send_story_to_relation')"
          placement="top"
        >
          <story-send-to-journalists-button
            :is-new-story="false"
            :has-unsaved-changes="false"
            :story-id="story.id"
            :story-updated-at="story.updated_at"
            :has-files="story.files_count > 0"
          >
            <v-icon
              type="envelope"
              size="lg"
              data-testid="Story__sendToEmail"
            />
          </story-send-to-journalists-button>
        </el-tooltip>
      </div>
      <div class="card-footer-item">
        <restricted-button
          :title="$t('forms.delete_story')"
          :loading="isDeleting"
          permission="story.delete"
          tag="a"
          action-class="button is-text is-narrow"
          @click.prevent="deleteStory"
        >
          <v-icon type="trash" size="lg" />
        </restricted-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'

import StorySendToJournalistsButton from '@/components/stories/StoryEdit/StorySendToJournalistsButton'

export default {
  components: { StorySendToJournalistsButton },

  mixins: [TruncateMixin, DatetimeMixin],

  props: {
    /** @type {HF_Story} */
    story: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isDeleting: false
    }
  },
  computed: {
    ...mapGetters(['activeBrand']),
    ...mapGetters('newsroom', ['findNewsroom']),
    viewUrl () {
      return this.$getUrl(`${this.story.newsroom_slug}/${this.story.slug}`, 'hypenews', false)
    },
    coverImage () {
      return this.$safeGet(this.story, 'cover.cropped', '')
    },
    status () {
      return this.story.is_published ? this.$t('general.published') : this.$t('general.not_published')
    },
    editLink () {
      return { name: 'story.edit', params: { storyId: this.story.id } }
    },
    newsroom () {
      return this.findNewsroom(this.story.newsroom_slug, 'slug')
    }
  },
  methods: {
    async deleteStory () {
      try {
        await this.$confirm(this.$t('pages.story_edit.sidebar_sticky_panel.confirm_story_delete'))
        this.isDeleting = true
        await StoryApiService.deleteStory(this.story.id)
        this.$emit('delete', this.story)
        this.$notify.success(this.$t('success.story_deleted'))
      } catch (error) {
        if (error === 'cancel') return
        this.$displayRequestError(error, this.$t('errors.cannot_delete_story'))
      } finally {
        this.isDeleting = false
      }
    }
  }
}
</script>

<style lang='scss'>
@import "~utils";

.Story {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid $white-ter;

  &__header {
    padding: $padding;
    flex-flow: column wrap;
    box-shadow: none;
  }

  &__LinkWrap {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column wrap;
    width: 100%;
  }

  &--draft {
    border-color: darken($white-ter, 3%);
    background: $grey-lighter;

    .card-footer, .card-footer-item {
      border-color: $grey-light;
    }
  }

  &__title {
    display: block;
    max-width: 100%;
    word-break: break-all;
  }

  &__body {
    flex: 1 1 auto;
    width: 100%;
    padding: $padding;
  }

  &__content {
    word-break: break-all;
  }

  &__date {
    color: $hf__text-muted;
    font-weight: bold;
  }

  &__meta {
    font-size: $size-7;
  }

  &__image {
    width: 100%;
    overflow: hidden;

    .floated {
      background: center/cover no-repeat;
      @include transit()
    }

    &-upload {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      background: $grey-light;
      transition: all 0.12s ease-in;
      color: $white;
      font-size: 1.6rem;
      width: 100%;
      position: relative;
      opacity: 0.6;
      text-align: center;

      .icon-faded {
        position: absolute;
        opacity: 0.2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8rem;
        transition: 0.15s;
        width: auto;
        height: auto;
      }
    }

    &:hover {
      .Story__image-upload {
        opacity: 1;

        .icon-faded {
          font-size: 10rem;
        }
      }

      .floated {
        transform: scale(1.05);
      }
    }
  }
}
</style>
